import type { Payload } from "@local/payload-client/src/types"

export const getVariantByVariantOptions = (
  productVariantOptions: Payload.ProductVariant["options"],
  productVariants: Payload.ProductVariant[] | undefined
): Payload.ProductVariant | undefined => {
  if (!productVariants) {
    return
  }
  return productVariants.find((pv) =>
    pv.options.every((option) =>
      productVariantOptions.some(
        (pvo) => pvo.option === option.option && pvo.value === option.value
      )
    )
  )
}
