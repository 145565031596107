import { CartOption, ProductOption } from "../../types"

export const getCartOptionsBySearchParams = (
  searchParams: URLSearchParams,
  productOptions: ProductOption[]
): CartOption[] => {
  const cartOptions: CartOption[] = []
  for (const productOption of productOptions) {
    const optionName = productOption.name.toLowerCase()

    // seatch param not provided for this option
    if (!searchParams.has(optionName)) {
      return []
    }

    const searchParamValue = searchParams.get(optionName)
    const productOptionValue = productOption.values.find(
      (pov) =>
        pov.localizedName === searchParamValue || pov.name === searchParamValue
    )

    // Not a valid value
    if (!productOptionValue) {
      return []
    }

    cartOptions.push({
      optionId: productOption.id,
      optionName: productOption.name,
      valueId: productOptionValue.id,
      valueName: productOptionValue.name,
      localizedName: productOptionValue.localizedName,
    })
  }

  return cartOptions
}
