import { create } from "zustand"
import type {
  Cart,
  CartBundleItem,
  CartItem,
  CartItemWithProduct,
  Giftcard,
  ProductPrice,
} from "../types"
import { Payload } from "@local/payload-client/src/types"
import { Currency } from "@local/i10n"
import {
  checkProductCurrency,
  getDepth,
  getDiscount,
  getPrice,
  getPricesFromProduct,
  getSubtotal,
  getTotal,
  priceOverride,
  priceOverrideFilter,
} from "../utils"

export interface MultiCartStore {
  /**
   * cartsUpdated is a variable that updates with the time in miliseconds whenever a change occurs in the store.
   * This was necessary because react consider object like Map as a single object and doesn't trigger a re-render.
   */
  cartsUpdated: number
  carts: Map<string, Cart>
  products: Map<Payload.Product["id"], Payload.Product>
  prices: Map<Payload.Product["id"], ProductPrice[]>
  initCart(
    cartId: string,
    products: Payload.Product[],
    initialItems: CartItem[],
    currency: Currency
  ): void
  getCart(cartId: string): Cart | undefined
  getItemsWithProduct(cartId: string): CartItemWithProduct[]
  addItem(cartId: string, item: Omit<CartItem, "cartKey" | "basePrice">): void
  removeItem(cartId: string, cartKey: number): void
  updateItem(cartId: string, item: Omit<CartItem, "price" | "basePrice">): void
  updateBundleItem(
    cartId: string,
    item: CartItem,
    bundleItem: CartBundleItem
  ): void
  addGiftcard(cartId: string, giftcard: Giftcard): void
  removeGiftcard(cartId: string, code: Giftcard["code"]): void
}

export const useMultiCartStore = create<MultiCartStore>((set, get) => ({
  cartsUpdated: new Date().getUTCMilliseconds(),
  carts: new Map<string, Cart>(),
  products: new Map<Payload.Product["id"], Payload.Product>(),
  prices: new Map<Payload.Product["id"], ProductPrice[]>(),
  initCart: (cartId, products, initialItems, currency) => {
    const cartsProducts = get().products
    const cartsPrices = get().prices
    const cart = get().carts

    for (const product of products) {
      if (cartsProducts.has(product.id)) {
        const currentDepth = getDepth(cartsProducts.get(product.id))
        const newDepth = getDepth(product)
        if (newDepth > currentDepth) {
          cartsProducts.set(product.id, product)
          cartsPrices.set(product.id, getPricesFromProduct(product, currency))
        }
      } else {
        cartsProducts.set(product.id, product)
        cartsPrices.set(product.id, getPricesFromProduct(product, currency))
      }
    }

    cart.set(cartId, {
      items: initialItems,
      giftcards: [],
      subtotal: getSubtotal(initialItems),
      total: getTotal(initialItems),
      discount: getDiscount(initialItems),
      currency: checkProductCurrency(products[0], currency)
        ? currency
        : Currency.USD,
    })

    set({
      cartsUpdated: new Date().getUTCMilliseconds(),
      products: cartsProducts,
      prices: cartsPrices,
    })
  },
  getCart: (cartId) => {
    return get().carts.get(cartId)
  },
  getItemsWithProduct: (cartId) => {
    const products = get().products
    const cart = get().carts.get(cartId)
    if (!cart) return []
    const storedItems = cart.items

    const items: any[] = []
    for (const item of storedItems) {
      const bundleItems: any[] = []
      if (item.bundleItems && item.bundleItems.length > 0) {
        for (const bundleItem of item.bundleItems) {
          const { cartKey, productName, options, ...rest } = bundleItem
          const product = products.get(bundleItem.productId)
          if (product) {
            const { variants, ...restProduct } = product
            const variant = variants?.find((v) => v.id === bundleItem.variantId)
            bundleItems.push({
              ...rest,
              product: restProduct,
              variant,
            })
          }
        }
      }

      const { cartKey, productName, options, price, basePrice, ...rest } = item
      const product = products.get(item.productId)
      if (product) {
        const { variants, ...restProduct } = product
        const variant = variants?.find((v) => v.id === item.variantId)
        if (bundleItems.length > 0) {
          items.push({ ...rest, bundleItems, product, variant })
        } else {
          items.push({
            ...rest,
            product: restProduct,
            variant,
          })
        }
      }
    }

    return items as CartItemWithProduct[]
  },
  addItem: (cartId, addedItem) => {
    const carts = get().carts
    const prices = get().prices
    const cart = carts.get(cartId)
    if (!cart) return
    const items = cart.items
    const cartKey = items.length + 1
    const newItems = [...items, { cartKey, ...addedItem }]

    const updatedItems: any[] = []
    for (const item of newItems) {
      const items = newItems.filter((i) => priceOverrideFilter(item, i))
      const quantity = items.reduce((acc, item) => acc + item.quantity, 0)
      const itemPrices = getPrice(quantity, prices.get(priceOverride(item))!)
      updatedItems.push({
        ...item,
        price: itemPrices.price,
        basePrice: itemPrices.basePrice,
      })
    }

    carts.set(cartId, {
      ...cart,
      items: updatedItems,
      subtotal: getSubtotal(updatedItems),
      total: getTotal(updatedItems),
      discount: getDiscount(updatedItems),
    })

    set({
      cartsUpdated: new Date().getUTCMilliseconds(),
      carts,
    })
  },
  removeItem: (cartId, cartKey) => {
    const carts = get().carts
    const prices = get().prices
    const cart = carts.get(cartId)
    if (!cart) return
    const items = cart.items
    const removedItem = items.find((item) => item.cartKey === cartKey)
    const newItems = items.filter((item) => item.cartKey !== cartKey)

    if (removedItem) {
      const updatedItems: any[] = []
      for (const item of newItems) {
        const items = newItems.filter((i) => priceOverrideFilter(item, i))
        const quantity = items.reduce((acc, item) => acc + item.quantity, 0)
        const itemPrices = getPrice(quantity, prices.get(priceOverride(item))!)
        updatedItems.push({
          ...item,
          price: itemPrices.price,
          basePrice: itemPrices.basePrice,
        })
      }

      carts.set(cartId, {
        ...cart,
        items: updatedItems,
        subtotal: getSubtotal(updatedItems),
        total: getTotal(updatedItems),
        discount: getDiscount(updatedItems),
      })

      set({
        cartsUpdated: new Date().getUTCMilliseconds(),
        carts,
      })
    }
  },
  updateItem: (cartId, updatedItem) => {
    const carts = get().carts
    const prices = get().prices
    const cart = carts.get(cartId)
    if (!cart) return
    const items = cart.items
    const index = items.findIndex((i) => i.cartKey === updatedItem.cartKey)

    if (index > -1) {
      items[index] = updatedItem
      const newItems = items

      const updatedItems: any[] = []
      for (const item of newItems) {
        const items = newItems.filter((i) => priceOverrideFilter(item, i))
        const quantity = items.reduce((acc, item) => acc + item.quantity, 0)
        const itemPrices = getPrice(quantity, prices.get(priceOverride(item))!)
        updatedItems.push({
          ...item,
          price: itemPrices.price,
          basePrice: itemPrices.basePrice,
        })
      }

      carts.set(cartId, {
        ...cart,
        items: updatedItems,
        subtotal: getSubtotal(updatedItems),
        total: getTotal(updatedItems),
        discount: getDiscount(updatedItems),
      })

      set({
        cartsUpdated: new Date().getUTCMilliseconds(),
        carts,
      })
    }
  },
  updateBundleItem: (cartId, item, bundleItem) => {
    const carts = get().carts
    const cart = carts.get(cartId)
    if (!cart) return
    const items = cart.items
    const index = items.findIndex((i) => i.cartKey === item.cartKey)

    if (index > -1) {
      const bundleIndex = items[index].bundleItems!.findIndex(
        (i) => i.cartKey === bundleItem.cartKey
      )

      if (bundleIndex > -1) {
        if (items[index].bundleItems) {
          items[index].bundleItems![bundleIndex] = bundleItem
          carts.set(cartId, {
            ...cart,
            items,
          })

          set({
            cartsUpdated: new Date().getUTCMilliseconds(),
            carts,
          })
        }
      }
    }
  },
  addGiftcard: (cartId, giftcard) => {
    const carts = get().carts
    const cart = carts.get(cartId)
    if (!cart) return
    carts.set(cartId, {
      ...cart,
      giftcards: [...cart.giftcards, giftcard],
    })
    set({
      cartsUpdated: new Date().getUTCMilliseconds(),
      carts,
    })
  },
  removeGiftcard: (cartId, code) => {
    const carts = get().carts
    const cart = carts.get(cartId)
    if (!cart) return
    carts.set(cartId, {
      ...cart,
      giftcards: [
        ...cart.giftcards.filter((giftcard) => giftcard.code !== code),
      ],
    })
    set({
      cartsUpdated: new Date().getUTCMilliseconds(),
      carts,
    })
  },
}))
