export function getDepth(obj: any): number {
  let depth = 0

  if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        depth = Math.max(depth, getDepth(obj[key]))
      }
    }
  }

  return depth + 1
}
