import { Payload } from "@local/payload-client/src/types"
import { Currency } from "@local/i10n"

export const checkProductCurrency = (
  product: Payload.Product,
  currency?: Currency
): boolean => {
  if (currency === undefined) return true
  // If currency is USD return false to set currency to undefined and force to default to USD prices
  if (currency === Currency.USD) return false
  if (!product) return false

  if (product.origPrices?.some((p) => p.currency === currency)) {
    if (product.prices.length > 0) {
      if (product.prices.every((p) => p[currency])) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}

export const getCurrency = (currency: string) => {
  switch (currency) {
    case "AUD":
      return Currency.AUD
    case "CAD":
      return Currency.CAD
    case "CHF":
      return Currency.CHF
    case "EUR":
      return Currency.EUR
    case "GBP":
      return Currency.GBP
    case "NZD":
      return Currency.NZD
    case "USD":
      return Currency.USD
    default:
      return Currency.USD
  }
}
