import { Payload } from "@local/payload-client/src/types"
import { checkProductCurrency } from "./checkProductCurrency"
import { Currency } from "@local/i10n"

export interface ProductPrice {
  quantity: number
  price: number
  basePrice: number
  discount: number
}

export const getPricesFromProduct = (
  product: Payload.Product,
  currency?: Currency
): ProductPrice[] => {
  const prices: ProductPrice[] = []
  // Check currency
  if (!checkProductCurrency(product, currency)) {
    currency = undefined
  }

  const origPrices = product.origPrices
  const hasPrices =
    product &&
    product.prices &&
    product.prices.length > 0 &&
    product.prices.every((p) => p.price && p.minQuantity)

  let minQuantity = 1
  let maxQuantity = 1

  if (hasPrices) {
    minQuantity = (product.prices as Payload.ProductPrice[])
      .map((p) => p.minQuantity)
      .filter((q) => q)
      .reduce((prev, curr) => (prev < curr ? prev : curr), minQuantity)

    maxQuantity = (product.prices as Payload.ProductPrice[])
      .map((p) => (p.maxQuantity ? p.maxQuantity : p.minQuantity))
      .filter((q) => q)
      .reduce((prev, curr) => (prev > curr ? prev : curr), maxQuantity)
  }

  let basePrice =
    origPrices?.find((p) => p.currency === currency)?.price ?? product.origPrice

  const pairs: { quantity: number; price: number }[] = []
  if (hasPrices) {
    for (let i = minQuantity; i <= maxQuantity; i++) {
      const price = (product.prices as Payload.ProductPrice[]).find((p) => {
        if (p.minQuantity) {
          return p.minQuantity <= i && (!p.maxQuantity || p.maxQuantity > i)
        }
      })
      if (price && price.price) {
        if (currency && currency !== "USD") {
          pairs.push({ quantity: i, price: price[currency] ?? price.price })
        } else {
          pairs.push({ quantity: i, price: price.price })
        }
      }
    }
  } else {
    for (let i = minQuantity; i <= maxQuantity; i++) {
      pairs.push({ quantity: i, price: basePrice * i })
    }
  }

  pairs.forEach((pair) => {
    prices.push({
      quantity: pair.quantity,
      price: pair.price,
      basePrice: basePrice,
      discount: basePrice - pair.price,
    })
  })

  return prices
}
