import {
  hasVariants,
  hasOptions,
  hasStock,
  getVariantOptionsByCartOptions,
  getVariantByVariantOptions,
  getCartOptionsByVariantOptions,
  getRegionalVariantFromVariant,
  getProductOptionsFromProduct,
} from ".."
import type { Payload } from "@local/payload-client/src/types"
import { CartBundleItem, CartItem, CartOption } from "../../types"
import { getCartOptionsBySearchParams } from "./getCartOptionsBySearchParams"

interface GetFirstVariantInStockArgs {
  product: Payload.Product
  region: string
  selectedOptions?: CartOption[]
  searchParams?: URLSearchParams | null
  priceOverride?: string
}

export const getFirstVariantInStock = ({
  product,
  region,
  selectedOptions,
  searchParams,
  priceOverride,
}: GetFirstVariantInStockArgs):
  | Omit<CartItem, "cartKey">
  | Omit<CartBundleItem, "cartKey">
  | undefined => {
  const quantity = 1

  // Product must have variants and options otherwise return undefined
  if (!hasVariants(product) || !hasOptions(product)) {
    return {
      giftWrap: product.giftwrap || null,
      options: [],
      preOrder: product.preorder,
      priceOverride,
      productId: product.id,
      productName: product.name,
      quantity: quantity,
      sku: product.sku || null,
      image: product.images[0].image.url,
    }
  }

  // For some reason this array comes reversed from the API
  const productVariants = product.variants as Payload.ProductVariant[]

  const productOptions = getProductOptionsFromProduct({
    productId: product.id,
    productMap: new Map<Payload.Product["id"], Payload.Product>([
      [product.id, product],
    ]),
    region,
  })
  let variantOptions: Payload.ProductVariant["options"] = []
  let variant: Payload.ProductVariant | undefined
  let cartOptions: CartOption[] = selectedOptions || []

  // If product has options
  if (productOptions && productOptions.length > 0) {
    const requiredOptions = productOptions
    // If selected options provided try building variant from those
    if (searchParams) {
      cartOptions = getCartOptionsBySearchParams(searchParams, productOptions)
      if (requiredOptions.length === cartOptions.length) {
        variant = getVariantByVariantOptions(
          getVariantOptionsByCartOptions(cartOptions, productOptions),
          productVariants
        )
      }
    }

    if (!variant || !hasStock({ product, variant })) {
      if (selectedOptions && selectedOptions.length > 0) {
        variantOptions = getVariantOptionsByCartOptions(
          selectedOptions,
          productOptions
        )
        variant = getVariantByVariantOptions(variantOptions, productVariants)
      }
    }

    // If variant is still undefined or doesn't have stock, try to find a variant that does
    if (!variant || !hasStock({ product, variant })) {
      for (let i = 0; i < productVariants.length; i++) {
        if (hasStock({ product, variant: productVariants[i] })) {
          variant = productVariants[i]
        }

        if (variant) {
          cartOptions = getCartOptionsByVariantOptions(
            productOptions,
            variant.options
          )

          if (requiredOptions.length === cartOptions.length) {
            break
          }
        }
      }
    }
  }

  if (!variant) {
    return
  }

  variant = getRegionalVariantFromVariant(variant, region)

  return {
    giftWrap: variant!.giftwrap || null,
    options: cartOptions,
    preOrder: variant?.preorder ?? product.preorder ?? null,
    priceOverride,
    productId: product.id,
    productName: product.name,
    quantity: quantity,
    sku: variant!.sku || null,
    variantId: variant!.id,
    variantName: variant!.name,
    image: variant!.image?.url || product.images[0].image.url,
  }
}
