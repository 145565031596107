import type { Payload } from "@local/payload-client/src/types"

export const hasStock = ({
  product,
  variant,
}: {
  product: Payload.Product
  variant?: Payload.ProductVariant
}): boolean => {
  // Product is not active, return false
  if (!product || !product.active) {
    return false
  }

  if (!variant) {
    // No variant provided
    // Product need to have backlog to sell without stock, otherwise return current stock status
    return product.backlog || product.hasStock
  } else {
    // Variant is not active, return false
    if (!variant.active) {
      return false
    }

    // Variant and product need to have backlog to sell without stock, otherwise return current stock status
    return variant.backlog || product.backlog || variant.hasStock
  }
}
