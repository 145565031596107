import { Payload } from "@local/payload-client/src/types"

export const getRegionalVariantFromVariant = (
  variant: Payload.ProductVariant | undefined,
  region: string
): Payload.ProductVariant | undefined => {
  if (!variant) {
    return
  }

  const { regionalNames, ...rest } = variant
  if (regionalNames) {
    const regionalName = regionalNames.find((regionalName) =>
      regionalName.region.includes(region)
    )
    if (regionalName) {
      return { ...rest, regionalNames, name: regionalName.name }
    }
  }
  return { ...rest, regionalNames }
}
