import { Payload } from "@local/payload-client/src/types"
import { ProductPrice } from "./getPricesFromProduct"
import { checkProductCurrency } from "./checkProductCurrency"
import { Currency } from "@local/i10n"

export const getPrice = (quantity: number, prices: ProductPrice[]) => {
  let price = prices?.find((p) => p.quantity === quantity)
  if (!price) {
    const maxQuantity = Math.max(...prices.map((p) => p.quantity))
    if (quantity > maxQuantity) {
      price = prices.find((p) => p.quantity === maxQuantity)
    }
  }
  return {
    price: price?.price || 0,
    basePrice: price?.basePrice || 0,
  }
}

export const getFullPrice = (product: Payload.Product, currency?: Currency) => {
  if (!checkProductCurrency(product, currency)) {
    currency = undefined
  }

  let basePrice
  if (currency) {
    basePrice = product.origPrices?.find((p) => p.currency === currency)
      ?.price as number
  } else {
    basePrice = product.origPrice
  }

  return basePrice
}
