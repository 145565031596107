import type { CartOption, ProductOption } from "../../types"
import type { Payload } from "@local/payload-client/src/types"

export const getCartOptionsByVariantOptions = (
  productOptions: ProductOption[],
  variantOptions: Payload.ProductVariant["options"],
  providedCartOptions: CartOption[] = []
): CartOption[] => {
  const cartOptions: CartOption[] = []
  for (const variantOption of variantOptions) {
    const productOption = productOptions.find(
      (po) => po.name === variantOption.option
    )
    const productOptionValue = productOption?.values.find(
      (pov) => pov.name === variantOption.value
    )
    const providedCartOption = providedCartOptions.find((pco) => {
      return (
        pco.optionId === productOption?.id &&
        pco.valueId === productOptionValue?.id
      )
    })

    if (
      productOption &&
      productOption.id &&
      productOption.name &&
      productOptionValue?.id &&
      productOptionValue?.name
    ) {
      cartOptions.push({
        optionId: productOption.id,
        optionName: productOption.name,
        valueId: productOptionValue.id,
        valueName: productOptionValue.name,
        localizedName:
          providedCartOption?.localizedName ||
          productOptionValue.localizedName.split("-")[0],
      })
    }
  }

  return cartOptions
}
