import { CartItem } from "../../types"

export const priceOverrideFilter = (
  referenceItem: CartItem,
  itemToCheck: CartItem
) => {
  const referenceItemPriceOverride = referenceItem.priceOverride
  const referenceItemProductId = referenceItem.productId
  const itemToCheckPriceOverride = itemToCheck.priceOverride
  const itemToCheckProductId = itemToCheck.productId

  if (referenceItemPriceOverride) {
    if (itemToCheckPriceOverride) {
      return referenceItemPriceOverride === itemToCheckPriceOverride
    } else {
      return referenceItemPriceOverride === itemToCheckProductId
    }
  } else {
    if (itemToCheckPriceOverride) {
      return referenceItemProductId === itemToCheckPriceOverride
    } else {
      return referenceItemProductId === itemToCheckProductId
    }
  }
}

export const priceOverride = (item: CartItem) => {
  if (item.priceOverride) {
    return item.priceOverride
  } else {
    return item.productId
  }
}
