import type { CartOption, ProductOption } from "../../types"
import type { Payload } from "@local/payload-client/src/types"

export const getVariantOptionsByCartOptions = (
  cartOptions: CartOption[],
  productOptions: ProductOption[]
): Payload.ProductVariant["options"] => {
  const variantOptions: Payload.ProductVariant["options"] = []

  for (const cartOption of cartOptions) {
    let option
    for (const productOption of productOptions) {
      for (const productOptionValue of productOption.values) {
        if (productOptionValue.id === cartOption.valueId) {
          option = {
            option: productOption.name,
            value: productOptionValue.name,
          }
        }
      }
    }

    if (option) {
      variantOptions.push(option)
    }
  }

  return variantOptions
}
