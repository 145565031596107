import { Payload } from "@local/payload-client/src/types"
import type { CartItem } from "../../types"
import { checkProductCurrency } from "./checkProductCurrency"
import { Currency } from "@local/i10n"

export const getDiscount = (items: CartItem[]) => {
  return items.reduce((acc, item) => acc + (item.basePrice! - item.price!), 0)
}

export const getDiscountPrice = (
  product: Payload.Product,
  currency?: Currency
) => {
  if (!checkProductCurrency(product, currency)) {
    currency = undefined
  }

  let basePrice
  if (currency) {
    basePrice = product.origPrices?.find((p) => p.currency === currency)
      ?.price as Number
  } else {
    basePrice = product.origPrice
  }

  const discountPrices =
    product?.prices &&
    product?.prices?.length > 0 &&
    (product?.prices).sort(
      (a, b) => Number(a.minQuantity) - Number(b.minQuantity)
    )[0]

  if (discountPrices) {
    const discountPrice =
      currency && currency !== "USD" && discountPrices.hasOwnProperty(currency)
        ? discountPrices[currency]
        : discountPrices.price

    return discountPrice
  }

  return basePrice
}
