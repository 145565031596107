import type { Payload } from "@local/payload-client/src/types"
import { ProductOption, ProductOptionValue } from "../../types"

interface GetProductOptionsArgs {
  productId: Payload.Product["id"]
  productMap?: Map<Payload.Product["id"], Payload.Product>
  region: string
}

export const getProductOptionsFromProduct = ({
  productId,
  productMap,
  region,
}: GetProductOptionsArgs): ProductOption[] => {
  const productOptions: Payload.ProductOption[] = []

  // Get options for product
  if (productMap?.has(productId)) {
    const product = productMap.get(productId)
    if (
      product &&
      product.id &&
      product.options &&
      product.options.length > 0
    ) {
      productOptions.push(...product.options)
    }
  }
  // Move color options to the top of the list
  const colorIndex = productOptions.findIndex(
    (o) => o.name?.toLowerCase().includes("color")
  )
  if (colorIndex > 0) {
    productOptions?.unshift(productOptions.splice(colorIndex, 1)[0])
  }

  // Move STYLE options to the top of the list
  const styleIndex = productOptions.findIndex(
    (o) => o.name?.toLowerCase().includes("style")
  )
  if (styleIndex > 0) {
    productOptions?.unshift(productOptions.splice(styleIndex, 1)[0])
  }

  const regionalOptions: ProductOption[] = []
  if (productOptions && productOptions.length > 0) {
    for (const option of productOptions) {
      const { values, ...rest } = option
      const regionalValues: ProductOptionValue[] = []
      const nameSet = new Set<string>()
      for (const value of values) {
        const { regionalNames, ...rest } = value
        let localizedName = value.localizedName
        if (regionalNames) {
          const regionalName = regionalNames.find((regionalName) =>
            regionalName.region.includes(region)
          )
          if (regionalName) {
            localizedName = regionalName.name
          }
        }

        const names = localizedName?.split("-")
        if (names && names.length > 0) {
          for (const name of names) {
            if (!nameSet.has(name)) {
              regionalValues.push({ ...rest, localizedName: name })
              nameSet.add(name)
            }
          }
        }
      }

      regionalOptions.push({ ...rest, values: regionalValues })
    }
  }

  return regionalOptions
}
